import './style.css'
import * as THREE from 'three'
import * as gsap from 'gsap';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {Scene} from './scene.js';
import Stats from 'three/examples/jsm/libs/stats.module'


function isTouchDevice() {
    return true;
    /*    return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));*/
}

const PROD = true;
let LIGHT = isTouchDevice();
let PARTICLES = !isTouchDevice();
let ANIMATED_PARTICLES = !isTouchDevice();
const SCROLL_PIN = false;
//let SCRAMBLE = !isTouchDevice();
let SCRAMBLE = false;

if (LIGHT) {
    document.getElementById('switch').style.display = 'none';
}

if (!PARTICLES) {
    document.body.classList.add('particles');
}

var self = {};
self.manager = new THREE.LoadingManager();
self.onLoadManager = function () {
    let short = 200;
    let long = 300;
    if (PROD) {
        setTimeout(() => {
            self.initMesh();
            self.resizeBackground();
            self.tick();
        }, 1000);
        short = 2000;
        long = 3000;
    }
    self.gifEl = document.getElementById('gif');
    setTimeout(() => {
        self.initVideo();
        document.body.classList.remove('progress');
        document.getElementById('loader').classList.add('fade');
        self.playVideo();
    }, short);
    setTimeout(() => {
        document.body.classList.remove('loader');
        self.currentSectionChange(self.currentSection);
    }, long);
}
self.manager.onProgress = function (item, loaded, total) {
    document.getElementById('loader').innerText = item;
    let tot = LIGHT ? 17 : 42;
    let percent = Math.round((loaded / tot * 100));
    document.getElementById('loader').innerText = percent + '%';
};

self.manager.onLoad = self.onLoadManager;

if (!PROD) {
    self.onLoadManager();
}

self.loader = new GLTFLoader(self.manager);

/**
 * Base
 */
// Canvas
self.canvas = document.querySelector('canvas.webgl')
self.container = document.getElementById('container')

// Scene
self.scene = new THREE.Scene();
if (!LIGHT) {
    self.scene2 = new THREE.Scene();
    self.scene3 = new THREE.Scene();
}

// BGs

self.onBVideoLoad = function () {
    self.blackhvideo.removeEventListener('loadedmetadata', self.onBVideoLoad, false);
    self.manager.itemEnd('/static/bg/blackh.mp4'); // notifying about end of loading process
}

self.onWVideoLoad = function () {
    self.whitehvideo.removeEventListener('loadedmetadata', self.onWVideoLoad, false);
    self.manager.itemEnd('/static/bg/whiteh.mp4'); // notifying about end of loading process
}

self.onRVideoLoad = function () {
    self.redhvideo.removeEventListener('loadedmetadata', self.onRVideoLoad, false);
    self.manager.itemEnd('/static/bg/redh.mp4'); // notifying about end of loading process
}

self.onDesktopVideoLoad = function () {
    self.desktopvideo.removeEventListener('loadedmetadata', self.onDesktopVideoLoad, false);
    self.manager.itemEnd('/static/videos/desktop.mp4'); // notifying about end of loading process
}

self.onMobileVideoLoad = function () {
    self.mobilevideo.removeEventListener('loadedmetadata', self.onMobileVideoLoad, false);
    self.manager.itemEnd('/static/videos/mobile.mp4'); // notifying about end of loading process
    self.mobilevideo.parentElement.removeChild(self.mobilevideo);
    self.mobilevideo = null;
}


self.desktopvideo = document.getElementById('video');
self.desktopvideo.addEventListener('loadedmetadata', self.onDesktopVideoLoad, false);
self.desktopvideo.preload = "auto";
self.desktopvideo.src = "/static/videos/desktop.mp4";

self.mobilevideo = document.getElementById('mobilevideo');
self.mobilevideo.addEventListener('loadedmetadata', self.onMobileVideoLoad, false);
self.mobilevideo.preload = "auto";
self.mobilevideo.src = "/static/videos/mobile.mp4";


self.blackhvideo = document.getElementById('blackh');
self.blackhvideo.addEventListener('loadedmetadata', self.onBVideoLoad, false);
self.blackhvideo.preload = "auto";
self.blackhvideo.src = "/static/bg/blackh.mp4";
self.blackhvideoTexture = new THREE.VideoTexture(self.blackhvideo);
self.blackhvideoTexture.wrapS = THREE.ClampToEdgeWrapping;
self.blackhvideoTexture.wrapT = THREE.RepeatWrapping;

self.whitehvideo = document.getElementById('whiteh');
self.whitehvideo.addEventListener('loadedmetadata', self.onWVideoLoad, false);
self.whitehvideo.preload = "auto";
self.whitehvideo.src = "/static/bg/whiteh.mp4";
self.whitehvideoTexture = new THREE.VideoTexture(self.whitehvideo);
self.whitehvideoTexture.wrapS = THREE.ClampToEdgeWrapping;
self.whitehvideoTexture.wrapT = THREE.RepeatWrapping;

self.redhvideo = document.getElementById('redh');
self.redhvideo.addEventListener('loadedmetadata', self.onRVideoLoad, false);
self.redhvideo.preload = "auto";
self.redhvideo.src = "/static/bg/redh.mp4";
self.redhvideoTexture = new THREE.VideoTexture(self.redhvideo);
self.redhvideoTexture.wrapS = THREE.ClampToEdgeWrapping;
self.redhvideoTexture.wrapT = THREE.RepeatWrapping;
if (LIGHT) {
    self.scene.background = null;
}
if (!LIGHT) {
    self.scene.background = self.blackhvideoTexture;
    self.scene2.background = self.whitehvideoTexture;
    self.scene3.background = self.redhvideoTexture;
}


self.manager.itemStart('/static/bg/whiteh.mp4');
self.manager.itemStart('/static/bg/blackh.mp4');
self.manager.itemStart('/static/bg/redh.mp4');
self.manager.itemStart('/static/videos/desktop.mp4');
self.manager.itemStart('/static/videos/mobile.mp4');

self.videoInited = false;
self.videoEl = false;
self.videoOK = false;
self.gifStart = 0;
self.gifEl = null;


self.initVideo = function () {
    if (!self.videoInited) {
        self.videoEl = document.getElementById('video');
        if (self.videoEl) {
            let promise = self.videoEl.play();
            if (promise !== undefined) {
                promise.then(_ => {
                    self.gifEl.remove();
                    self.videoEl.style.display = 'block';
                    self.videoInited = true;
                    self.videoOK = true;
                }).catch(error => {
                    self.videoEl.style.display = 'none'
                    self.gifEl.style.display = 'block';
                    self.videoInited = true;
                    self.videoOK = false;
                });
            }
        }
    }
}

self.playVideo = function () {
    //self.videoEl.currentTime = 0;
    //self.videoEl.play();
    self.gifStart = new Date().getTime();
}

self.soundBtn = document.getElementById("sound");
self.soundBtn.addEventListener('click', function () {
    self.minFPS = 999999;
    self.FPSaverage = 0;
    self.FPSsize = 0;
    if (self.videoOK) {
        self.videoEl.muted = !self.videoEl.muted;
        self.soundBtn.classList.remove('play');
        if (self.videoEl.muted) {
            self.soundBtn.classList.add('stop');
        } else {
            self.soundBtn.classList.remove('stop');
        }
    } else {
        let diff = new Date().getTime() - self.gifStart;
        let delay = ((diff / 1000) % 24);
        self.gifEl.style.display = 'none'
        self.videoEl.style.display = 'block';
        self.appHeight();
        self.videoEl.currentTime = delay;
        self.videoEl.play();
        self.videoEl.muted = !self.videoEl.muted;
        self.soundBtn.classList.remove('play');
        if (self.videoEl.muted) {
            self.soundBtn.classList.add('stop');
        } else {
            self.soundBtn.classList.remove('stop');
        }

    }
});


// HDRI
if (PROD) {
    self.HDRloader = new RGBELoader(self.manager).setPath('./static/hdr/');
    self.HDRloader.load('gold.hdr', function (texture) {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        self.scene.environment = texture;
        if (!LIGHT) {
            self.scene3.environment = texture;
        }
    });
    if (!LIGHT) {
        self.HDRloader.load('white.hdr', function (texture) {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            self.scene2.environment = texture;
        });
    }
}

/**
 * Objects
 */
self.objectsDistance = 4

self.gold = null;
self.standard = null;
self.special = null;
self.specialY = null;
self.specialX = null;
self.sectionMeshes = [];
self.baseSectionMeshes = [];
self.uuids = [];
self.cUUID = null;

if (PROD) {
    if (LIGHT) {
        self.loader.load('../static/glb/genesis_1K.glb', function (genesis) {
            self.gold = genesis.scene;
            self.gold.scale.set(10, 10, 10);
        });
    } else {
        self.loader.load('../static/glb/genesis_1K.glb', function (genesis) {
            self.gold = genesis.scene;
            self.gold.scale.set(10, 10, 10);
        });
    }


    if (!LIGHT) {
        self.loader.load('../static/glb/mix_1K.glb', function (standard) {
            self.standard = standard.scene;
            self.standard.scale.set(10, 10, 10);
        });

        self.loader.load('../static/glb/lava_1K.glb', function (special) {
            self.special = special.scene;
            self.special.scale.set(10, 10, 10);
        });
    }
}

/*Promise.all([
    self.loader.loadAsync('../static/glb/genesis-compressed.glb'),
    self.loader.loadAsync('../static/glb/mix-compressed.glb'),
    self.loader.loadAsync('../static/glb/lava-compressed.glb')
]).then((results) => {
    self.gold = results[0].scene;
    self.gold.scale.set(10, 10, 10);
    self.standard = results[1].scene;
    self.standard.scale.set(10, 10, 10);
    self.special = results[2].scene;
    self.special.scale.set(10, 10, 10);
    self.initMesh();

}).catch((err) => {
    console.log(err);
});*/


self.initMesh = function () {
    self.box = new THREE.Box3().setFromObject(self.gold);
    var delta = self.box.getSize().y / 2;

    self.gold.position.y = -delta;
    self.gold.position.x = 0;
    self.gold.position.z = -0.3

    if (!LIGHT) {
        self.gold.position.y = self.standard.position.y = self.special.position.y = -delta;
        self.gold.position.x = self.standard.position.x = self.special.position.x = 0;
        self.gold.position.z = self.standard.position.z = self.special.position.z = -0.3
    }


    self.scene.add(self.gold);
    if (!LIGHT) {
        self.scene2.add(self.standard);
        self.scene3.add(self.special);
    }
    self.sectionMeshes = [self.gold, self.standard, self.special];
    if (LIGHT) {
        self.sectionMeshes = [self.gold];
    }

    /**
     * Particles
     */

    // Geometry
    if (PARTICLES) {
        const particlesCount = 400
        const positions = new Float32Array(particlesCount * 3)
        const sprite = new THREE.TextureLoader().load('../static/disc_p.png');

        for (let i = 0; i < particlesCount; i++) {
            positions[i * 3 + 0] = (Math.random() - 0.5) * 10 * Math.random() * 4
            positions[i * 3 + 1] = self.objectsDistance * 0.5 - Math.random() * self.objectsDistance * 3 * Math.random() * 4
            positions[i * 3 + 2] = (Math.random() - 0.5) * 10 * Math.random() * 4
        }

        const particlesGeometry = new THREE.BufferGeometry()
        particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

        // Material
        const particlesMaterial = new THREE.PointsMaterial({
            color: '#f0dc82',
            sizeAttenuation: true,
            size: 0.12,
            map: sprite,
            alphaTest: 0.5,
            transparent: true
        });
        const particlesMaterialLava = new THREE.PointsMaterial({
            color: '#972e16',
            sizeAttenuation: true,
            size: 0.12,
            map: sprite,
            alphaTest: 0.5,
            transparent: true
        });
        const particlesMaterialWhite = new THREE.PointsMaterial({
            color: '#d5d5d5',
            sizeAttenuation: true,
            size: 0.12,
            map: sprite,
            alphaTest: 0.5,
            transparent: true
        });


        // Points
        self.particles = new THREE.Points(particlesGeometry, particlesMaterial)
        self.particlesLava = new THREE.Points(particlesGeometry, particlesMaterialLava)
        self.particlesWhite = new THREE.Points(particlesGeometry, particlesMaterialWhite)
        self.particles.rotation.x = Math.random() * 6;
        self.particles.rotation.y = Math.random() * 6;
        self.particles.rotation.z = Math.random() * 6;
        self.particlesLava.rotation.x = Math.random() * 6;
        self.particlesLava.rotation.y = Math.random() * 6;
        self.particlesLava.rotation.z = Math.random() * 6;
        self.particlesWhite.rotation.x = Math.random() * 6;
        self.particlesWhite.rotation.y = Math.random() * 6;
        self.particlesWhite.rotation.z = Math.random() * 6;
        self.scene.add(self.particles);
        self.scene2.add(self.particlesWhite);
        self.scene3.add(self.particlesLava);
    }

}


/**
 * Lights
 */


const spotDistance = 50;
self.light = new THREE.SpotLight(0xf0dc82, 1);
self.light.position.set(0, 0, 0);
self.light.castShadow = true;
self.light.shadow.bias = -0.0001;
self.light.shadow.mapSize.width = 1024 * 4;
self.light.shadow.mapSize.height = 1024 * 4;


self.light2 = self.light.clone();
self.light2.intensity = 1;


self.light3 = new THREE.SpotLight(0xf0dc82, 1);
self.light3.position.set(spotDistance, -spotDistance, spotDistance);
self.light3.castShadow = true;
self.light3.shadow.bias = -0.0001;
self.light3.shadow.mapSize.width = 1024 * 4;
self.light3.shadow.mapSize.height = 1024 * 4;


self.light4 = self.light3.clone();
self.light4.intensity = 1;

self.light5 = new THREE.SpotLight(0xf0dc82, 3);
self.light5.position.set(-spotDistance, spotDistance, spotDistance);
self.light5.castShadow = true;
self.light5.shadow.bias = -0.0001;
self.light5.shadow.mapSize.width = 1024 * 4;
self.light5.shadow.mapSize.height = 1024 * 4;

self.light6 = self.light5.clone();
self.light6.intensity = 1;

self.light7 = new THREE.SpotLight(0xf0dc82, 1);
self.light7.position.set(-spotDistance, -spotDistance, spotDistance);
self.light7.castShadow = true;
self.light7.shadow.bias = -0.0001;
self.light7.shadow.mapSize.width = 1024 * 4;
self.light7.shadow.mapSize.height = 1024 * 4;

self.light8 = self.light7.clone();
self.light8.intensity = 1;


self.dirLight = new THREE.DirectionalLight(0xffffff, 1);
self.dirLight.castShadow = true;
self.dirLight.rotation.set(1, Math.PI, Math.PI);
self.dirLight.position.set(0, 0, 10);
self.dirLight.shadowCameraLeft = -3000;
self.dirLight.shadowCameraRight = 3000;
self.dirLight.shadowCameraTop = 3500;
self.dirLight.shadowCameraBottom = -3000;

self.dirLight2 = self.dirLight.clone();
self.dirLight2.intensity = 1;
self.dirLight2.position.set(5, 0, 10);

self.backLight = self.dirLight.clone();
self.backLight.position.set(0, 0, -10);

self.backLight2 = self.backLight.clone();
self.backLight2.intensity = 1;

self.scene.add(self.dirLight, self.backLight);
if (!LIGHT) {
    self.scene2.add(self.dirLight2, self.backLight2, self.light, self.light2, self.light4, self.light6, self.light8);
}


/**
 * Sizes
 */
self.sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

self.resizeBackground = function () {
    let targetAspect = self.sizes.width / self.sizes.height;
    let imageAspect = 1920 / 1080;
    let factor = imageAspect / targetAspect;

    if (self.scene.background) {
        self.scene.background.offset.x = factor > 1 ? (1 - 1 / factor) / 2 : 0;
        self.scene.background.repeat.x = factor > 1 ? 1 / factor : 1;
        self.scene.background.offset.y = factor > 1 ? 0 : (1 - factor) / 2;
        self.scene.background.repeat.y = factor > 1 ? 1 : factor;
        if (!LIGHT) {
            self.scene2.background.offset.x = factor > 1 ? (1 - 1 / factor) / 2 : 0;
            self.scene2.background.repeat.x = factor > 1 ? 1 / factor : 1;
            self.scene2.background.offset.y = factor > 1 ? 0 : (1 - factor) / 2;
            self.scene2.background.repeat.y = factor > 1 ? 1 : factor;
            self.scene3.background.offset.x = factor > 1 ? (1 - 1 / factor) / 2 : 0;
            self.scene3.background.repeat.x = factor > 1 ? 1 / factor : 1;
            self.scene3.background.offset.y = factor > 1 ? 0 : (1 - factor) / 2;
            self.scene3.background.repeat.y = factor > 1 ? 1 : factor;
        }
    }
}


window.addEventListener('resize', () => {
    // Update sizes
    self.sizes.width = window.innerWidth
    self.sizes.height = window.innerHeight

    if (PROD) {
        // Update camera
        self.camera.aspect = self.sizes.width / self.sizes.height
        self.camera.updateProjectionMatrix();
        if (!LIGHT) {
            self.camera2.aspect = self.sizes.width / self.sizes.height
            self.camera2.updateProjectionMatrix()
            self.camera3.aspect = self.sizes.width / self.sizes.height
            self.camera3.updateProjectionMatrix()
        }
        // Update renderer
        self.renderer.setSize(self.sizes.width, self.sizes.height)
        self.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }
    self.doneLimit = 0;
    self.resizeBackground();
    self.onRoadmapScroll();
})

/**
 * Scroll
 */
self.scrollY = window.scrollY
self.currentSection = 0;

window.addEventListener('scroll', (event) => {
    self.scrollY = window.scrollY;
    const newSection = Math.round(scrollY / self.sizes.height);

    if (newSection != self.currentSection) {
        self.currentSection = newSection
        self.currentSectionChange(newSection);
    }
    if (!LIGHT) {
        const s = (document.getElementById('video-container').offsetHeight - self.scrollY) / document.getElementById('video-container').offsetHeight;
        document.documentElement.style.setProperty('--video-alpha', s);
    }
})

/**
 * Cursor
 */
self.cursor = {}
self.cursor.x = 0
self.cursor.y = 0

if (!isTouchDevice()) {
    window.addEventListener('mousemove', (event) => {
        self.cursor.x = event.clientX / self.sizes.width - 0.5
        self.cursor.y = event.clientY / self.sizes.height - 0.5
    })
}


/**
 * Camera
 */
if (PROD) {
// Group
    self.cameraGroup = new THREE.Group()
    self.scene.add(self.cameraGroup)
    if (!LIGHT) {
        self.cameraGroup2 = new THREE.Group()
        self.scene2.add(self.cameraGroup2)
        self.cameraGroup3 = new THREE.Group()
        self.scene3.add(self.cameraGroup3)
    }


// Base camera
    self.camera = new THREE.PerspectiveCamera(75, self.sizes.width / self.sizes.height, 0.1, 100)
    self.camera.position.z = 6;
    self.cameraGroup.add(self.camera)
    if (!LIGHT) {
        self.camera2 = new THREE.PerspectiveCamera(75, self.sizes.width / self.sizes.height, 0.1, 100)
        self.camera2.position.z = 6;
        self.cameraGroup2.add(self.camera2)

        self.camera3 = new THREE.PerspectiveCamera(75, self.sizes.width / self.sizes.height, 0.1, 100)
        self.camera3.position.z = 6;
        self.cameraGroup3.add(self.camera3)
    }
}


/**
 * Renderer
 */
if (PROD) {
    self.renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: self.canvas,
        alpha: true
    });
    self.renderer.setSize(self.sizes.width, self.sizes.height)
    self.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

//renderer.toneMapping = THREE.ReinhardToneMapping;
    self.renderer.toneMapping = THREE.ReinhardToneMapping;
//renderer.toneMappingExposure = 1;
    self.renderer.toneMappingExposure = 2;
    self.renderer.shadowMap.enabled = true;
//renderer.outputEncoding = THREE.sRGBEncoding;
    self.renderer.sortObjects = false;
    self.renderer.autoClear = false;
}


if (PROD && !LIGHT) {
// Textures
    self.textures = [];
    for (var i = 0; i < 6; i++)
        self.textures[i] = new THREE.ImageUtils.loadTexture('../static/transition/transition' + (i + 1) + '.png');

    self.quadmaterial = new THREE.ShaderMaterial({

        uniforms: {

            tDiffuse1: {
                type: "t",
                value: null
            },
            tDiffuse2: {
                type: "t",
                value: null
            },
            mixRatio: {
                type: "f",
                value: 0.0
            },
            threshold: {
                type: "f",
                value: 0.1
            },
            useTexture: {
                type: "i",
                value: 1,
            },
            tMixTexture: {
                type: "t",
                value: self.textures[3]
            }
        },
        vertexShader: [

            "varying vec2 vUv;",

            "void main() {",

            "vUv = vec2( uv.x, uv.y );",
            "gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",

            "}"

        ].join("\n"),
        fragmentShader: [

            "uniform float mixRatio;",

            "uniform sampler2D tDiffuse1;",
            "uniform sampler2D tDiffuse2;",
            "uniform sampler2D tMixTexture;",

            "uniform int useTexture;",
            "uniform float threshold;",

            "varying vec2 vUv;",

            "void main() {",

            "vec4 texel1 = texture2D( tDiffuse1, vUv );",
            "vec4 texel2 = texture2D( tDiffuse2, vUv );",

            "if (useTexture==1) {",

            "vec4 transitionTexel = texture2D( tMixTexture, vUv );",
            "float r = mixRatio * (1.0 + threshold * 2.0) - threshold;",
            "float mixf=clamp((transitionTexel.r - r)*(1.0/threshold), 0.0, 1.0);",

            "gl_FragColor = mix( texel1, texel2, mixf );",
            "} else {",

            "gl_FragColor = mix( texel2, texel1, mixRatio );",

            "}",
            "}"

        ].join("\n")

    });

}

// Camera pos
// Z between 1 & 4
// Y between 0 & -3
// X -0.25

self.definedPoints = [
    new THREE.Vector3(0, 0.9, 0.75),
    new THREE.Vector3(0, 0.9, 0.75),
    new THREE.Vector3(-1, 0.60, 0.15),
    new THREE.Vector3(0, 0.15, -1.2),
    new THREE.Vector3(1.75, 0.85, -0.15),
    new THREE.Vector3(1.75 / 2, 0.35, 1),
    new THREE.Vector3(0, -0.2, 2),
    new THREE.Vector3(0, -0.2, 2),
    new THREE.Vector3(0, -1.82, 2)
];
self.enableVect = true;

self.spline = new THREE.CatmullRomCurve3(self.definedPoints);


self.tg = [
    new THREE.Vector3(0, 1, 0),
    new THREE.Vector3(0, 1, 0),
    new THREE.Vector3(0, 1, 0),
    new THREE.Vector3(0, 1, 0),
    new THREE.Vector3(0, 0.5, 0),
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(0, -2, 0)
];

self.tgline = new THREE.CatmullRomCurve3(self.tg);

/**
 * Animate
 */
self.clock = new THREE.Clock();
self.previousTime = 0;
self.currentState = 1;
self.targetValue = 1;
self.speed = 0.01;
self.tdelta = 0;

// 60 fps
self.tinterval = 1 / 60;
self.isSwitching = self.false;
self.particleSpeed = 0.0075;
self.initTime = 3;
self.aTurn = THREE.Math.degToRad(360);
self.minFPS = 9999;
self.FPSsize = 0;
self.FPSaverage = 0;

//const stats = Stats();
//document.body.appendChild(stats.dom);

self.tick = () => {

    // Call tick again on the next frame
    window.requestAnimationFrame(self.tick);
    //stats.update();
    self.tdelta += self.clock.getDelta();

    if (self.needScrumble) {
        self.scramble();
    }
    if (self.horizontalScroll && !(self.horizontalScrollValue > 100 || self.horizontalScrollValue < 0)) {
        self.roadmapScroll.scrollTo(0, self.horizontalLimit - 250);
    }


    if (self.tdelta > self.tinterval) {

        let theDelta = self.clock.getDelta();
        const elapsedTime = self.clock.getElapsedTime()
        const deltaTime = elapsedTime - self.previousTime;
        self.previousTime = elapsedTime;

        //console.log("FPS : " + 1/ deltaTime);
        /*        const FPS = 1/ deltaTime;
                if(!self.FPSaverage){
                    self.FPSaverage = FPS;
                    self.FPSsize = 1;
                }else{
                    self.FPSsize++;
                    self.FPSaverage = self.FPSaverage + ((FPS - self.FPSaverage)/self.FPSsize);
                }
                console.log("MIN FPS : " +self.minFPS);
                console.log("MOY FPS : " +self.FPSaverage);
                self.minFPS = Math.min(self.minFPS, 1/ deltaTime);*/

        // Animate meshes
        for (const mesh of self.sectionMeshes) {
            //mesh.rotation.x += deltaTime * 0.1
            if (self.openedPage) {
                mesh.rotation.y += deltaTime * 0.45;
                mesh.rotation.y = mesh.rotation.y % self.aTurn;
            } else {
                if (mesh.rotation.y > self.aTurn || mesh.rotation.y < 0 || mesh.rotation.y === 0) {
                    mesh.rotation.y = 0;
                } else if (mesh.rotation.y > self.aTurn / 2) {
                    mesh.rotation.y += deltaTime * 5;
                } else {
                    mesh.rotation.y -= deltaTime * 5;
                }
            }
        }

        // Animate camera
        //camera.position.y = camera2.position.y = -scrollY / sizes.height * objectsDistance
        let maxS = document.documentElement.scrollHeight - self.sizes.height;
        let percent = self.scrollY / maxS;
        let theZ = 2 + (percent * 8);
        if (percent > 0.5) {
            theZ = 2 + (8 - (percent * 8));
        }
        //camera.position.z = camera2.position.z = theZ;


        const parallaxX = self.cursor.x * 0.5
        const parallaxY = 0.2 + -self.cursor.y * 0.5


        self.cameraGroup.position.x += (parallaxX - self.cameraGroup.position.x) * 5 * deltaTime
        self.cameraGroup.position.y += (parallaxY - self.cameraGroup.position.y) * 5 * deltaTime

        if (!LIGHT) {
            self.cameraGroup2.position.x += (parallaxX - self.cameraGroup2.position.x) * 5 * deltaTime
            self.cameraGroup2.position.y += (parallaxY - self.cameraGroup2.position.y) * 5 * deltaTime

            self.cameraGroup3.position.x += (parallaxX - self.cameraGroup3.position.x) * 5 * deltaTime
            self.cameraGroup3.position.y += (parallaxY - self.cameraGroup3.position.y) * 5 * deltaTime

        }
        var camPos = self.spline.getPoint(percent);

        if (self.enableVect) {
            self.camera.position.x = camPos.x;
            self.camera.position.y = camPos.y;
            self.camera.position.z = camPos.z;
            if (!LIGHT) {
                self.camera2.position.x = camPos.x;
                self.camera2.position.y = camPos.y;
                self.camera2.position.z = camPos.z;
                self.camera3.position.x = camPos.x;
                self.camera3.position.y = camPos.y;
                self.camera3.position.z = camPos.z;
            }
        }

        var tgVal = self.tgline.getPoint(percent);
        self.controls.target = tgVal;
        if (!LIGHT) {
            self.controls2.target = tgVal;
            self.controls3.target = tgVal;
        }


        // Render
        let diff = self.currentState - self.targetValue;
        if (diff > 1 || diff < -1) {
            self.currentState = self.targetValue;
        } else {
            if (diff > 0) {
                self.currentState -= self.speed;
            }
            if (diff < 0) {
                self.currentState += self.speed;
            }
        }
        self.currentState = roundToTwo(self.currentState);


        if (!LIGHT) {
            self.transitionParams.transition = THREE.Math.smoothstep(self.currentState, 0.3, 0.7);
            self.quadmaterial.uniforms.mixRatio.value = self.transitionParams.transition;
        }

        // Particles
        if (ANIMATED_PARTICLES) {
            for (let i = 0; i < self.scene.children.length; i++) {
                const object = self.scene.children[i];
                if (object instanceof THREE.Points) {
                    object.rotation.y = elapsedTime * (i < 4 ? i + 1 : -(i + 1)) * self.particleSpeed;
                }
            }
            if (!LIGHT) {
                for (let i = 0; i < self.scene2.children.length; i++) {
                    const object = self.scene2.children[i];
                    if (object instanceof THREE.Points) {
                        object.rotation.y = elapsedTime * (i < 4 ? i + 1 : -(i + 1)) * self.particleSpeed;
                    }
                }
                for (let i = 0; i < self.scene3.children.length; i++) {
                    const object = self.scene3.children[i];
                    if (object instanceof THREE.Points) {
                        object.rotation.y = elapsedTime * (i < 4 ? i + 1 : -(i + 1)) * self.particleSpeed;
                    }
                }
            }
        }

        //stats.update()


        // if (elapsedTime < self.initTime) {
        //self.scenes[0].render(theDelta, true, self.cameras[0])
        //self.scenes[1].render(theDelta, true, self.cameras[1])
        //self.scenes[2].render(theDelta, true, self.cameras[2])
        // }

        // Prevent render both scenes when it's not necessary
        if (self.transitionParams.transition == 0) {
            self.scenes[self.currentIndexes[1]].render(theDelta, false, self.cameras[self.currentIndexes[1]]);
            if (self.isSwitching) {
                self.switchScene();
                self.isSwitching = false;
            }
        } else if (self.transitionParams.transition == 1) {
            self.scenes[self.currentIndexes[0]].render(theDelta, false, self.cameras[self.currentIndexes[0]]);
            if (self.isSwitching) {
                self.switchScene();
                self.isSwitching = false;
            }
        } else {
            self.isSwitching = true;
            self.scenes[self.currentIndexes[0]].render(theDelta, true, self.cameras[self.currentIndexes[0]])
            self.scenes[self.currentIndexes[1]].render(theDelta, true, self.cameras[self.currentIndexes[1]])
            self.renderer.setRenderTarget(null)
            self.renderer.clear()
            self.renderer.render(self.mainScene, self.cameraOrtho)
        }


        //renderer.render(scene, camera)

        self.tdelta = self.tdelta % self.tinterval;
        if (self.openedPage === "2") {
            self.onRoadmapScroll();
        }
    }
}

if (PROD) {
    self.controls = new OrbitControls(self.camera, self.renderer.domElement);
    self.sceneA = Scene(self.scene, self.renderer, self.controls);
    self.scenes = [self.sceneA];
    self.cameras = [self.camera];
    self.classes = ['genesis', 'mix', 'lava'];
    self.currentIndexes = [0];


    if (!LIGHT) {
        self.quadmaterial.uniforms.tDiffuse1.value = self.scenes[self.currentIndexes[0]].fbo.texture;
        self.controls2 = new OrbitControls(self.camera2, self.renderer.domElement);
        self.controls3 = new OrbitControls(self.camera3, self.renderer.domElement);
        self.sceneB = Scene(self.scene2, self.renderer, self.controls2);
        self.sceneC = Scene(self.scene3, self.renderer, self.controls3);
        self.scenes = [self.sceneA, self.sceneB, self.sceneC];
        self.cameras = [self.camera, self.camera2, self.camera3];
        self.classes = ['genesis', 'mix', 'lava'];
        self.currentIndexes = [0, 1];
        self.quadmaterial.uniforms.tDiffuse1.value = self.scenes[self.currentIndexes[0]].fbo.texture;
        self.quadmaterial.uniforms.tDiffuse2.value = self.scenes[self.currentIndexes[1]].fbo.texture;
    }


// Render first time


// Main scene
    self.mainScene = new THREE.Scene()

    self.cameraOrtho = new THREE.OrthographicCamera(
        window.innerWidth / -2,
        window.innerWidth / 2,
        window.innerHeight / 2,
        window.innerHeight / -2,
        -10,
        10,
    )

    self.quadgeometry = new THREE.PlaneGeometry(
        window.innerWidth,
        window.innerHeight,
    )

    const quad = new THREE.Mesh(self.quadgeometry, self.quadmaterial)
    self.mainScene.add(quad)
}


self.transitionParams = {
    transition: 0.5,
    transitionSpeed: 3.0,
}

if (LIGHT) {
    self.transitionParams = {
        transition: 1,
        transitionSpeed: 3.0,
    }
}

self.switchScene = function () {
    switch (JSON.stringify(self.currentIndexes)) {
        case "[0,1]":
            self.currentIndexes = [2, 1];
            break;
        case "[2,1]":
            self.currentIndexes = [2, 0];
            break;
        case "[2,0]":
            self.currentIndexes = [1, 0];
            break;
        case "[1,0]":
            self.currentIndexes = [1, 2];
            break;
        case "[1,2]":
            self.currentIndexes = [0, 2];
            break;
        case "[0,2]":
            self.currentIndexes = [0, 1];
            break;
        default:
            console.log('default');
            self.currentIndexes = [0, 1];
    }
    self.quadmaterial.uniforms.tDiffuse1.value = self.scenes[self.currentIndexes[0]].fbo.texture;
    self.quadmaterial.uniforms.tDiffuse2.value = self.scenes[self.currentIndexes[1]].fbo.texture;
    self.switchBtn.classList.remove('loading');
}


function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

self.switchAction = function () {
    if (!self.isSwitching) {
        self.switchBtn.classList.add('loading');
        if (self.currentState < 0.3) {
            self.targetValue = 1;
            document.body.classList.remove(self.classes[self.currentIndexes[1]])
            document.body.classList.add(self.classes[self.currentIndexes[0]])
        } else if (self.currentState > 0.8) {
            self.targetValue = 0;
            document.body.classList.remove(self.classes[self.currentIndexes[0]])
            document.body.classList.add(self.classes[self.currentIndexes[1]])
        } else {
            // In progress
        }
    }
}

if (PROD && !LIGHT) {
    document.body.classList.add(self.classes[self.currentIndexes[0]])
    document.body.classList.remove(self.classes[self.currentIndexes[1]])
    document.body.onkeydown = function (e) {
        if (e.keyCode == 32) {
            e.preventDefault();
            e.stopPropagation();
            self.switchAction();
        }
    }
    self.switchBtn = document.getElementById('switch');
    self.switchBtn.addEventListener('click', event => {
        LIGHT = true;
        PARTICLES = false;
        ANIMATED_PARTICLES = false;
        self.scene2 = null;
        self.scene2 = null;
        self.sceneB = null;
        self.sceneC = null;
        self.camera2 = null;
        self.camera3 = null;
        self.cameraGroup2 = null;
        self.cameraGroup3 = null;
        self.scene.remove(self.particles);
        self.standard = null;
        self.special = null;
        self.whitehvideoTexture = null;
        self.redhvideoTexture = null;
        // TODO self.switchAction();
    });
}

self.isMobileVideo = false;
self.isSizedVideo = false;
self.isSizedDocument = false;
self.appHeight = () => {
    if (!self.isSizedDocument) {
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
        document.documentElement.style.setProperty('--app-width', `${window.innerWidth}px`);
        self.isSizedDocument = true;
    }
    if (self.videoInited) {
        if (window.innerHeight > window.innerWidth) {
            if (!self.isMobileVideo) {
                let vel = document.getElementById('video');
                //vel.src = 'static/videos/mobile.mp4';
                if (!self.isSizedVideo) {
                    vel.width = window.innerWidth;
                    vel.height = window.innerHeight;
                    self.isSizedVideo = true;
                }
                self.isMobileVideo = true;
            }
        } else {
            if (self.isMobileVideo) {
                document.getElementById('video').src = 'static/videos/desktop.mp4';
                self.isMobileVideo = false;
            }
        }
    }
};
window.addEventListener('resize', self.appHeight);
self.appHeight();

const absolutePosition = function (el) {
    var
        found,
        left = 0,
        top = 0,
        width = 0,
        height = 0,
        offsetBase = absolutePosition.offsetBase;
    if (!offsetBase && document.body) {
        offsetBase = absolutePosition.offsetBase = document.createElement('div');
        offsetBase.style.cssText = 'position:absolute;left:0;top:0';
        document.body.appendChild(offsetBase);
    }
    if (el && el.ownerDocument === document && 'getBoundingClientRect' in el && offsetBase) {
        var boundingRect = el.getBoundingClientRect();
        var baseRect = offsetBase.getBoundingClientRect();
        found = true;
        left = boundingRect.left - baseRect.left;
        top = boundingRect.top - baseRect.top;
        width = boundingRect.right - boundingRect.left;
        height = boundingRect.bottom - boundingRect.top;
    }
    return {
        found: found,
        left: left,
        top: top,
        width: width,
        height: height,
        right: left + width,
        bottom: top + height
    };
}

self.forcing = false;
self.goToSection = function (section, force) {
    if (!self.forcing) {
        self.forcing = force;
        const el = document.getElementById('section-' + section);
        var rect = absolutePosition(el);
        gsap.gsap.to(window, {
            duration: 2, scrollTo: {y: rect.top, ease: "Power3.easeInOut"}, onComplete: function () {
                self.forcing = false;
            }
        });
        if (self.openedPage) {
            self.openPage(self.openedPage);
        }
        if (self.mobileMenuOpened) {
            self.closeMenu();
        }
    }
};

self.openedPage = null;
self.openPage = function (page) {
    if (page === self.openedPage) {
        document.getElementById("page-" + page.toString()).classList.remove('display');
        document.body.classList.remove('no-scroll');
        self.openedPage = null;
        self.wrappersEls.forEach(function (wrappersEl) {
            wrappersEl.classList.remove('hide');
        });
    } else if (self.openedPage) {
        document.getElementById("page-" + self.openedPage.toString()).classList.remove('display');
        self.openedPage = page;
        document.getElementById("page-" + page.toString()).classList.add('display');
    } else {
        self.openedPage = page;
        document.getElementById("page-" + page.toString()).classList.add('display');
        document.body.classList.add('no-scroll');
        self.wrappersEls.forEach(function (wrappersEl) {
            wrappersEl.classList.add('hide');
        });
    }

}

self.navTriggers = document.querySelectorAll('.nav-trigger');
self.legendsEls = document.querySelectorAll('.legend');
self.wrappersEls = document.querySelectorAll('.section-wrapper');
self.closeEls = document.querySelectorAll('.close-page');
self.sectionToScramble = null;
self.elToScramble = null;
self.txtToScramble = null;
self.needScrumble = false;
self.shuffleCount = 4;
self.random = "*&%!1234567ABC"
self.sectionEls = Array.from(document.getElementsByTagName('section'));
self.sectionH2s = Array.from(document.getElementsByTagName('h2'));
self.shuffle = 0
self.mojicount = 0

self.currentSectionChange = function (section) {
    self.navTriggers.forEach(function (navTrigger) {
        navTrigger.classList.remove('active');
        if (navTrigger.dataset.section === self.currentSection.toString()) {
            navTrigger.classList.add('active');
            if (SCRAMBLE) {
                self.triggerScramble(self.currentSection);
            }
        }
    });

    self.legendsEls.forEach(function (legendElement) {
        legendElement.classList.remove('active');
    });
    self.wrappersEls.forEach(function (wrappersEl) {
        wrappersEl.classList.remove('active');
    });
    if (SCRAMBLE) {
        self.sectionH2s.forEach(function (sectionH2) {
            sectionH2.innerText = "";
        });
    }
    self.sectionEls.forEach(function (sectionEl) {
        sectionEl.classList.remove('active');
        if (sectionEl.id === "section-" + self.currentSection.toString()) {
            sectionEl.classList.add('active');
            if (!SCRAMBLE) {
                self.sectionToScramble = sectionEl;
                self.endScrambleForSection();
            }
        }
    });
    if (self.currentSection > 0 && !LIGHT) {
        document.getElementById('switch').style.display = 'block';
    } else {
        document.getElementById('switch').style.display = 'none';
    }
}

gsap.gsap.registerPlugin(ScrollToPlugin);
/*
gsap.gsap.registerPlugin(ScrollTrigger);
*/

self.sections = gsap.gsap.utils.toArray(".section");

/*if (SCROLL_PIN) {
    ScrollTrigger.defaults({
        scrub: true,
        //markers: true,
    });
}*/

/*self.sections.forEach((eachPanel, i) => {
    // const mainAnim = gsap.timeline({ paused: true });

    if (SCROLL_PIN) {
        ScrollTrigger.create({
            trigger: eachPanel,
            onEnter: () => self.goToSection(i, false)
        });

        if (i === 0) {
            ScrollTrigger.create({
                trigger: eachPanel,
                start: "center",
                end: "center",
                onEnterBack: () => self.goToSection(i, false)
            });
        } else {
            ScrollTrigger.create({
                trigger: eachPanel,
                start: "bottom bottom",
                onEnterBack: () => self.goToSection(i, false)
            });
        }

    }
});*/

self.navTriggers.forEach(function (navTrigger) {
    navTrigger.addEventListener("click", function (event) {
        self.goToSection(event.currentTarget.dataset.section, true);
    });
});
self.wrappersEls.forEach(function (wrappersEl) {
    wrappersEl.addEventListener("click", function (event) {
        self.openPage(event.currentTarget.dataset.page);
    });
});

self.closeEls.forEach(function (closeEl) {
    closeEl.addEventListener("click", function (event) {
        self.openPage(event.currentTarget.dataset.page);
    });
});

self.endScrambleForSection = function () {
    const theP = Array.from(self.sectionToScramble.getElementsByTagName('p'))[0];
    theP.classList.add('active');
    const theW = self.sectionToScramble.querySelector('.section-wrapper');
    if (theW) {
        theW.classList.add('active');
    }
}


self.scramble = function () {
    const PROGRESS = self.elToScramble.textContent.length;

    if (PROGRESS < self.txtToScramble.length) {
        self.needScrumble = true;
        if (self.shuffle < self.shuffleCount) {
            const mix = Math.floor(Math.random() * (self.random.length))
            const output = self.txtToScramble.slice(0, self.mojicount)
            self.elToScramble.innerText = output + self.random[mix]
            self.shuffle++;
        } else {
            self.mojicount++
            self.shuffle = 0
        }
    } else {
        if (PROGRESS >= self.txtToScramble.length) {
            self.elToScramble.innerText = self.txtToScramble;
            self.needScrumble = false;
            self.endScrambleForSection();
        }
    }
}


self.triggerScramble = function (section) {
    self.sectionEls.forEach(function (section) {
        if (section.id === "section-" + self.currentSection.toString()) {
            self.shuffle = 0;
            self.mojicount = 0;
            self.sectionToScramble = section;
            self.elToScramble = Array.from(self.sectionToScramble.getElementsByTagName('h2'))[0];
            self.txtToScramble = self.elToScramble.dataset.text.toString();
            self.elToScramble.innerText = ""
            self.scramble();
        }
    });
}

window.scrollTo(0, 0);


// Stepper
self.stepperTriggers = document.querySelectorAll('.stepper-trigger');
self.stepperEls = document.querySelectorAll('.stepper-el');
self.currentStep = 1;

self.goToStep = function (step) {
    self.stepperTriggers.forEach(function (stepTrigger) {
        stepTrigger.classList.remove('active');
        if (stepTrigger.dataset.step === step) {
            stepTrigger.classList.add('active');
        }
    });
    self.stepperEls.forEach(function (stepperEl) {
        stepperEl.classList.remove('active');
        stepperEl.classList.remove('active' + self.currentStep.toString());
        stepperEl.classList.add('active' + step.toString());
        if (stepperEl.dataset.step === step) {
            stepperEl.classList.add('active');
        }
    });
    self.currentStep = step;

}
self.stepperTriggers.forEach(function (stepTrigger) {
    stepTrigger.addEventListener("click", function (event) {
        event.stopPropagation();
        event.preventDefault();
        self.goToStep(event.currentTarget.dataset.step, true);
    });
});


// Roadmap
self.roadmap80 = document.getElementById("roadmap-80");
self.roadmapScroll = document.getElementById("roadmap-scroll");
self.roadmapScrollLine = document.getElementById("roadmap-line");
self.roadmapScrollLineGold = document.getElementById("roadmap-line-gold");
self.splashes = Array.from(self.roadmapScroll.getElementsByClassName('splash'));
self.doneLimit = 0;
self.horizontalLimit = 0;
self.horizontalScroll = false;
self.horizontalScrollFull = false;
self.horizontalScrollValue = 0;
self.onRoadmapScroll = function () {
    self.splashes.forEach(function (splash) {
        if (splash.offsetTop - 282 < self.roadmapScroll.scrollTop) {
            splash.classList.add('active');
        } else {
            splash.classList.remove('active');
        }
        if (self.doneLimit === 0 && !splash.classList.contains('done')) {
            self.doneLimit = splash.offsetTop - 92;
        }

        self.horizontalLimit = self.roadmap80.offsetTop + 125;

    });
    self.roadmapScrollLine.style.height = self.roadmapScroll.scrollTop + 300 + "px";
    self.roadmapScrollLineGold.style.height = Math.min(self.roadmapScroll.scrollTop + 300, self.doneLimit) + "px";
    if (self.horizontalLimit && self.roadmapScroll.scrollTop + 250 > self.horizontalLimit) {
        // Scroll horizontal
        if (!self.horizontalScrollFull) {
            self.horizontalScroll = true;
        }
    }

    if (self.horizontalLimit && self.roadmapScroll.scrollTop + 250 < self.horizontalLimit) {
        // Scroll horizontal
        if (self.horizontalScrollFull && self.scrollUp) {
            self.horizontalScroll = true;
        }
    }
}

self.roadmapScroll.addEventListener('scroll', function (event) {
    if (self.horizontalScroll) {
        event.preventDefault();
        event.stopPropagation();
        if (self.scrollUp) {
            self.horizontalScrollValue--;
        } else {
            self.horizontalScrollValue++;
        }
        self.roadmap80.style.width = self.horizontalScrollValue + "%";
        if (self.horizontalScrollValue > 100) {
            self.horizontalScrollFull = true;
        }
        if (self.horizontalScrollValue < 0) {
            self.horizontalScrollFull = false;
        }
        if (self.horizontalScrollValue > 100 || self.horizontalScrollValue < 0) {
            self.horizontalScroll = false;
            self.roadmapScroll.style.overflow = 'auto';
        } else {
            self.roadmapScroll.scrollTo(0, self.horizontalLimit - 250);
            self.roadmapScroll.style.overflow = 'hidden';
        }
    }
});
self.scrollUp = false;
self.roadmapScroll.addEventListener('wheel', function (event) {

    if (event.wheelDelta) {
        self.scrollUp = event.wheelDelta > 0;
    }
    self.scrollUp = event.deltaY < 0;
    self.onWheel();
});


self.roadmapScroll.addEventListener('onmousewheel', function (event) {

    if (event.wheelDelta) {
        self.scrollUp = event.wheelDelta > 0;
    }
    self.scrollUp = event.deltaY < 0;
    self.onWheel();
});


// TEST
var elem = document.getElementById("roadmap-scroll");

var lastY = 0;
elem.addEventListener('touchmove', function (e) {
    var currentY = e.touches[0].clientY;
    if (currentY > lastY) {
        //self.scrollUp = false;
    } else if (currentY < lastY) {
        //self.scrollUp = true;
    }
    lastY = currentY;
    self.scrollUp = false;
    self.onWheel();
});

if (elem.addEventListener) {
    if ('onwheel' in document) {
        // IE9+, FF17+, Ch31+
        elem.addEventListener("wheel", self.onWheel);
    } else if ('onmousewheel' in document) {
        // устаревший вариант события
        elem.addEventListener("mousewheel", self.onWheel);
    } else {
        // Firefox < 17
        elem.addEventListener("MozMousePixelScroll", self.onWheel);
    }
} else { // IE8-
    elem.attachEvent("onmousewheel", self.onWheel);
}

self.onWheel = function (e) {
    if (self.horizontalScroll) {
        if (self.scrollUp) {
            self.horizontalScrollValue -= 5;
        } else {
            self.horizontalScrollValue += 5;
        }
        if (self.horizontalScrollValue >= 100) {
            self.horizontalScrollFull = true;
        }
        if (self.horizontalScrollValue <= 0) {
            self.horizontalScrollFull = false;
        }
        if (self.horizontalScrollValue > 100 || self.horizontalScrollValue < 0) {
            self.horizontalScroll = false;
            self.roadmapScroll.style.overflow = 'auto';
        }
        if (self.horizontalScrollValue > 100) {
            self.horizontalScrollValue = 100;
        }
        if (self.horizontalScrollValue < 0) {
            self.horizontalScrollValue = 0;
        }
        self.roadmap80.style.width = self.horizontalScrollValue + "%";

    }
}

// Legends
self.legendTriggers = document.querySelectorAll('li.thumb');
self.legendTriggersList = document.querySelectorAll('ul.thumbs')[0];
self.legendEls = document.querySelectorAll('.legend-el');
self.legendContainer = document.getElementById('legends-container');
self.currentLegend = 0;

self.goToLegend = function (legend) {
    self.legendContainer.classList.add('show');
    self.legendTriggersList.classList.add('small');
    self.legendTriggers.forEach(function (legendTrigger) {
        legendTrigger.classList.remove('active');
        if (legendTrigger.dataset.legend === legend) {
            legendTrigger.classList.add('active');
        }
    });
    self.legendEls.forEach(function (legendEl) {
        legendEl.classList.remove('active');
        legendEl.classList.remove('active' + self.currentLegend.toString());
        legendEl.classList.add('active' + legend.toString());
        Array.from(legendEl.getElementsByClassName('has-animation')).forEach(function (ha) {
            ha.classList.remove('animate-in');
        });
        if (legendEl.dataset.legend === legend) {
            legendEl.classList.add('active');
            setTimeout(function () {
                Array.from(legendEl.getElementsByClassName('has-animation')).forEach(function (ha) {
                    ha.classList.add('animate-in');
                });
            }, 500);
        }
    });
    self.currentLegend = legend;

}
self.legendTriggers.forEach(function (legendTrigger) {
    legendTrigger.addEventListener("click", function (event) {
        event.stopPropagation();
        event.preventDefault();
        self.goToLegend(event.currentTarget.dataset.legend, true);
    });
});


self.mobileMenuOpened = false;
self.nav = Array.from(document.getElementsByTagName('nav'))[0];
self.mobileNavTrigger = document.getElementById("mobile-nav");
self.mobileNavTrigger.addEventListener("click", function (event) {
    if (!self.mobileMenuOpened) {
        self.openMenu();
    } else {
        self.closeMenu();
    }
});

self.openMenu = function () {
    self.mobileNavTrigger.classList.add('open');
    self.nav.classList.add('open');
    document.body.classList.add('open');
    self.mobileMenuOpened = true;
}

self.closeMenu = function () {
    self.mobileNavTrigger.classList.remove('open');
    self.nav.classList.remove('open');
    document.body.classList.remove('open');
    self.mobileMenuOpened = false;
}

self.collectionViewer = false;
self.currentCollectionPhoto = 0;
self.photoViewer = document.getElementById('photo-viewer');
self.photoViewerClose = document.getElementById('photo-viewer-close');
self.photoViewerImg = document.getElementById('photo-viewer-img');
self.photoViewerLeft = document.getElementById('photo-viewer-l');
self.photoViewerRight = document.getElementById('photo-viewer-r');
self.photoEls = document.querySelectorAll('[data-photo]');
Array.from(self.photoEls).forEach(function (a) {
    a.addEventListener("click", function (event) {
        self.openPhoto(event.currentTarget.dataset.photo);
    });
});

self.photoViewerClose.addEventListener("click", function (event) {
    self.closePhoto();
});

self.photoViewer.addEventListener("click", function (event) {
    if (!self.collectionViewer) {
        self.closePhoto();
    }
});

self.photoViewerLeft.addEventListener("click", function (event) {
    if (self.collectionViewer) {
        self.movePhoto(false);
    }
});
self.photoViewerRight.addEventListener("click", function (event) {
    if (self.collectionViewer) {
        self.movePhoto(true);
    }
});
self.movePhoto = function (right) {
    if (right) {
        self.currentCollectionPhoto++;
        if (self.currentCollectionPhoto > 24) {
            self.currentCollectionPhoto = 0;
        }
        self.photoViewerImg.src = '/static/collection/' + self.currentCollectionPhoto + '.jpg';
    } else {
        self.currentCollectionPhoto--;
        if (self.currentCollectionPhoto < 0) {
            self.currentCollectionPhoto = 24;
        }
        self.photoViewerImg.src = '/static/collection/' + self.currentCollectionPhoto + '.jpg';
    }

}
self.openPhoto = function (src) {
    self.photoViewer.classList.remove('collection');
    self.collectionViewer = false;
    if (src === 'collection') {
        self.collectionViewer = true;
        self.photoViewer.classList.add('open');
        self.photoViewer.classList.add('collection');
        self.photoViewerImg.src = '/static/collection/0.jpg';
    } else {
        self.photoViewerImg.src = '/static/' + src;
        self.photoViewer.classList.add('open');
    }
}

self.closePhoto = function () {
    self.photoViewer.classList.remove('open');
}
