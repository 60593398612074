import * as THREE from 'three'

var Scene = function(
    scene,
    renderer,
    controls
) {
    var o = {};
    o.scene = scene;
    o.controls = controls;
    o.renderer = renderer;
    o.camera = new THREE.PerspectiveCamera(
        35,
        window.innerWidth / window.innerHeight,
        1,
        10000,
    )
    o.camera.position.z = 6;
    o.renderTargetParameters = {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBFormat,
        stencilBuffer: false,
    }
    o.fbo = new THREE.WebGLRenderTarget(
        window.innerWidth,
        window.innerHeight,
        o.renderTargetParameters,
    )

    o.render = function(delta, rtt, camera) {
        o.controls.update();
        if (rtt) {
            o.renderer.setRenderTarget(o.fbo) // addition
            o.renderer.clear()
            o.renderer.render(o.scene, camera)
        } else {
            o.renderer.setRenderTarget(null)
            o.renderer.render(o.scene, camera)
        }

    }
    return o;
}

export { Scene };
